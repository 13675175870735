import React, { FC, useEffect } from 'react';
import { Platform } from 'react-native';
import { withIAPContext, setup } from 'react-native-iap';

import { useFeature } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';

import View from './View';
import { useViewModel } from './viewModel';
import Screen from '../navigation/components/ApplicationScreen';
import type { MainProps } from './types';

const Main: FC<MainProps> = (props) => {
    const inAppPurchasesEnabled = useFeature('inAppPurchases')?.enabled || false;

    useEffect(() => {
        if (inAppPurchasesEnabled && ['ios', 'android'].includes(Platform.OS)) {
            const storekitMode: any = 'STOREKIT1_MODE';
            setup({ storekitMode });
        }
    }, [inAppPurchasesEnabled]);

    const viewModelProps: Partial<MainProps> = useViewModel(props);
    return <View {...viewModelProps} />;
};
Main.displayName = 'ApplicationMain';

const Application = {
    Main: ['ios', 'android'].includes(Platform.OS) && withIAPContext ? withIAPContext(Main) : Main,
    View,
    useViewModel,
    Screen,
};

export default Application;
