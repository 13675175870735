var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.createFlowIdUserDataClient=exports.FlowIdUserDataClient=void 0;var _createClass2=_interopRequireDefault(require("@babel/runtime/helpers/createClass"));var _classCallCheck2=_interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));var _inherits2=_interopRequireDefault(require("@babel/runtime/helpers/inherits"));var _possibleConstructorReturn2=_interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));var _getPrototypeOf2=_interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));var _slicedToArray2=_interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));var _regenerator=_interopRequireDefault(require("@babel/runtime/regenerator"));
var _constants=require("@24i/nxg-core-utils/src/constants");



var _nxgIntegrationBackstage=require("@24i/nxg-integration-backstage");

var _nxgSdkQuantum=require("@24i/nxg-sdk-quantum");
var _UserData=require("@24i/nxg-sdk-smartott-shared/src/context/UserData");
var _initApp=require("@24i/nxg-sdk-smartott/src/Application/initApp");function _createSuper(t){var r=_isNativeReflectConstruct();return function(){var e,o=(0,_getPrototypeOf2.default)(t);if(r){var s=(0,_getPrototypeOf2.default)(this).constructor;e=Reflect.construct(o,arguments,s);}else e=o.apply(this,arguments);return(0,_possibleConstructorReturn2.default)(this,e);};}function _isNativeReflectConstruct(){try{var t=!Boolean.prototype.valueOf.call(Reflect.construct(Boolean,[],function(){}));}catch(t){}return(_isNativeReflectConstruct=function _isNativeReflectConstruct(){return!!t;})();}
















var removeToken=function removeToken(){return _regenerator.default.async(function removeToken$(_context){while(1)switch(_context.prev=_context.next){case 0:return _context.abrupt("return",_nxgSdkQuantum.Storage.removeItem(_constants.ASYNC_STORAGE_KEY_USER_TOKEN));case 1:case"end":return _context.stop();}},null,null,null,Promise);};

var getUserDataFromStorage=function getUserDataFromStorage(){var obj;return _regenerator.default.async(function getUserDataFromStorage$(_context2){while(1)switch(_context2.prev=_context2.next){case 0:_context2.next=2;return _regenerator.default.awrap(
_nxgSdkQuantum.Storage.getItem(_constants.ASYNC_STORAGE_KEY_USER));case 2:obj=_context2.sent;return _context2.abrupt("return",
JSON.parse(obj||''));case 4:case"end":return _context2.stop();}},null,null,null,Promise);};

var removeUserDataFromStorage=function removeUserDataFromStorage(){return _regenerator.default.async(function removeUserDataFromStorage$(_context3){while(1)switch(_context3.prev=_context3.next){case 0:return _context3.abrupt("return",_nxgSdkQuantum.Storage.removeItem(_constants.ASYNC_STORAGE_KEY_USER));case 1:case"end":return _context3.stop();}},null,null,null,Promise);};

var performLogout=function performLogout(logoutUrl,details){var formBody;return _regenerator.default.async(function performLogout$(_context4){while(1)switch(_context4.prev=_context4.next){case 0:
formBody=Object.entries(details).
map(function(_ref){var _ref2=(0,_slicedToArray2.default)(_ref,2),key=_ref2[0],value=_ref2[1];return encodeURIComponent(key)+"="+encodeURIComponent(value);}).
join('&');_context4.prev=1;_context4.next=4;return _regenerator.default.awrap(


fetch(logoutUrl,{
method:'POST',
headers:{
'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'
},
body:formBody
}));case 4:_context4.next=6;return _regenerator.default.awrap(

removeUserDataFromStorage());case 6:_context4.next=8;return _regenerator.default.awrap(
removeToken());case 8:_context4.next=13;break;case 10:_context4.prev=10;_context4.t0=_context4["catch"](1);

console.error('Logout error:',_context4.t0);case 13:case"end":return _context4.stop();}},null,null,[[1,10]],Promise);};var


FlowIdUserDataClient=function(_BackstageUserDataCli){(0,_inherits2.default)(FlowIdUserDataClient,_BackstageUserDataCli);var _super=_createSuper(FlowIdUserDataClient);function FlowIdUserDataClient(){var _this;(0,_classCallCheck2.default)(this,FlowIdUserDataClient);for(var _len=arguments.length,args=new Array(_len),_key=0;_key<_len;_key++){args[_key]=arguments[_key];}_this=_super.call.apply(_super,[this].concat(args));_this.
loginFlowID=function _callee(token,user){return _regenerator.default.async(function _callee$(_context5){while(1)switch(_context5.prev=_context5.next){case 0:_context5.prev=0;_context5.next=3;return _regenerator.default.awrap(

(0,_UserData.setUserDataToStorage)(user));case 3:_context5.next=8;break;case 5:_context5.prev=5;_context5.t0=_context5["catch"](0);

console.error('Logout err:',_context5.t0);case 8:case"end":return _context5.stop();}},null,null,[[0,5]],Promise);};_this.



logoutFlowID=function _callee2(){var _flowIdRuntimeConfig$,_flowIdRuntimeConfig$2,_flowIdRuntimeConfig$3;var flowIdRuntimeConfig,userData,_flowIdRuntimeConfig$4,apiKey,userKey,secret,logoutUrl,details;return _regenerator.default.async(function _callee2$(_context6){while(1)switch(_context6.prev=_context6.next){case 0:
flowIdRuntimeConfig=(0,_initApp.getRuntimeConfig)();_context6.next=3;return _regenerator.default.awrap(
getUserDataFromStorage());case 3:userData=_context6.sent;if(!(

flowIdRuntimeConfig!=null&&(_flowIdRuntimeConfig$=flowIdRuntimeConfig.custom)!=null&&(_flowIdRuntimeConfig$2=_flowIdRuntimeConfig$.features)!=null&&(_flowIdRuntimeConfig$3=_flowIdRuntimeConfig$2.logoutFlowId)!=null&&_flowIdRuntimeConfig$3.logoutUrl&&userData!=null&&userData.id)){_context6.next=9;break;}_flowIdRuntimeConfig$4=

flowIdRuntimeConfig.custom.features.logoutFlowId,apiKey=_flowIdRuntimeConfig$4.apiKey,userKey=_flowIdRuntimeConfig$4.userKey,secret=_flowIdRuntimeConfig$4.secret,logoutUrl=_flowIdRuntimeConfig$4.logoutUrl;

details={
apiKey:apiKey||'',
userKey:userKey||'',
secret:secret||'',
UID:userData.id
};_context6.next=9;return _regenerator.default.awrap(

performLogout(logoutUrl,details));case 9:case"end":return _context6.stop();}},null,null,null,Promise);};return _this;}return(0,_createClass2.default)(FlowIdUserDataClient);}(_nxgIntegrationBackstage.BackstageUserDataClient);exports.FlowIdUserDataClient=FlowIdUserDataClient;




var createFlowIdUserDataClient=function createFlowIdUserDataClient(params){return(
new FlowIdUserDataClient(params));};exports.createFlowIdUserDataClient=createFlowIdUserDataClient;