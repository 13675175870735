var _interopRequireDefault=require("@babel/runtime/helpers/interopRequireDefault");Object.defineProperty(exports,"__esModule",{value:true});exports.i18nOptions=void 0;
var _en=_interopRequireDefault(require("@24i/prd-localization-files-prj1470cwc/dist/en.json"));
var _es=_interopRequireDefault(require("./es/es.json"));


_en.default.common.appName={
title:'StreamBluu',
description:'StreamBluu'
};

var i18nOptions={
resources:{
en:{sott:_en.default},
es:{sott:_es.default}
},
react:{
useSuspense:false
},
fallbackLng:'en'
};exports.i18nOptions=i18nOptions;